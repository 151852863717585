import React,{useState, useEffect} from "react"
import { Link } from "@StarberryUtils"
import Results from "../../../templates/search-results-template";
import "../assets/styles/_index.scss"
import { parseSearchUrl, propertyH1, savedSearchParams } from "../../../Components/SearchResult/utils";
import { UserObjectStoreProvider, useUserObjectState, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";

const ResidentialLettings = (props) => {

    useEffect(() => {
        if (isAuthenticated) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const fullpath = props.location.pathname
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath); 

    return (
        <>
            <Results 
                locationname="/property/to-rent/" 
                pcategorytype="residential" 
                fullpathone={fullpathone} 
                fullpathname={fullpath} 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype="To Let" 
                userObjects={userObjects}
            /> 
        </>
    )
}

// export default ResidentialLettings

const SearchResultComp = (props) => {
    return(
        <UserObjectStoreProvider>
            <ResidentialLettings {...props} />
        </UserObjectStoreProvider>
    )
}


export default SearchResultComp;


// import * as React from "react"
// import SearchTemplate from "../../../templates/search-template";
// import { navigate } from "@reach/router";
// import { navigate as navigateGatsby } from "gatsby";
// // import SEO from "../../../components/seo";
// class Search extends React.PureComponent {
//     constructor(props) {
//         super(props);
//         this.state = {
//             query: "",
//             min: "",
//             max: "",
//             bedrooms: "",
//             building: "",
//             searchType: "lettings",
//             pcategorytype: "residential"
//         }
//     }
//     changeStateAndUrl = (searchState) => {
//         let urls = [];
//         if(typeof searchState.query !== "undefined") {
//             if(searchState.query !== "") {
//                 urls.push(`in-${searchState.query}`)
//                 this.setState({ query: searchState.query })
//             } else {
//                 this.setState({ query: searchState.query })
//             }
//         }
//         // create building URL
//         if(typeof searchState.menu !== "undefined") {
//             if(searchState.menu.building) {
//                 urls.push(`type-${searchState.menu.building}`)
//                 this.setState({ building: searchState.menu.building })
//             } else {
//                 this.setState({ building: ""})
//             }
//         } else if(this.state.building !== "") {
//             urls.push(`type-${this.state.building}`)
//         }
//         // create bedroom URL
//         if(searchState.range && searchState.range.bedroom) {
//             let bedroom = searchState.range.bedroom.min;
//             if(bedroom) {
//                 urls.push(`${bedroom}-and-more-bedrooms`)
//                 this.setState({ bedrooms: bedroom })
//             } else {
//                 this.setState({ bedrooms: "" })
//             }
//         }
//         // create Price min and Max URL
//         if(typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
//             let price = searchState.range.price;
//             let min = price.min;
//             let max = price.max;
//             if(min && max) {
//                 urls.push(`between-${min}-and-${max}`)
//                 this.setState({ min, max })
//             } else {
//                 if(min) {
//                     urls.push(`over-${min}`)
//                     this.setState({ min })
//                 } else if(this.state.min !== "") {
//                     this.setState({ min: ""})
//                 } 
//                 if(max) {
//                     urls.push(`under-${max}`)
//                     this.setState({ max })
//                 } else if(this.state.max !== ""){
//                     this.setState({ max: ""})
//                 }
//             } 
//         } else {
//             let min = this.state.min;
//             let max = this.state.max;
//             if(min !== "" && max !== "") {
//                 urls.push(`between-${min}-and-${max}`)
//                 this.setState({ min, max })
//             } else {
//                 if(min) {
//                     urls.push(`over-${min}`)
//                     this.setState({ min })
//                 } else {
//                     this.setState({ min: ""})
//                 } 
//                 if(max) {
//                     urls.push(`under-${max}`)
//                     this.setState({ max })
//                 } else {
//                     this.setState({ max: ""})
//                 }
//             } 
//         }
//         if(typeof searchState.page !== "undefined") {
//             if(searchState.page !== "" && searchState.page != 1) {
//                 urls.push(`page-${searchState.page}`)
//                 this.setState({ page: searchState.page })
//             }
//         }
//         let url = urls.join('/');
//         let newRoute = '/property/to-rent/'+url;
//         navigate(newRoute)
//     }
//     componentDidMount() {
//         if(window)
//             window.scroll({
//                 top: -100,
//                 behavior: 'smooth'
//             });
//         const { params } = this.props;
//         const searchParams = params['*'].split('/');
//         searchParams.forEach(param => {
//             if(param.includes("in-")) {
//                 this.setState({ query: param.substring(3)})
//             }
//             if(param.includes("under-")) {
//                 this.setState({ max: param.substring(6)})
//             }
//             if(param.includes("over-")) {
//                 this.setState({ min: param.substring(5)})
//             }
//             if(param.includes("-and-more-bedrooms")) {
//                 this.setState({ bedrooms: param.split("-")[0]})
//             }
//             if(param.includes("type-")) {
//                 this.setState({ building: param.split("-")[1]})
//             }
//             if(param.includes("between-")) {
//                 this.setState({ 
//                     min: param.split("-")[1],
//                     max: param.split("-")[3]
//                 })
//             }
//         });
//     }
    
//     changeToMapUrl = () => {
//         let pathname = this.props.location.pathname.replace("/property/", "/property-map/");
//         let url = ""
//         if(pathname.includes("page-")) {
//             let urls = pathname.split("/");
//             urls.pop();
//             url = urls.join("/");
//         } else {
//             url = pathname;
//         }
//         navigateGatsby(url)
//     }

//     changeToListUrl = () => {
//         let url = this.props.location.pathname.replace("/property-map/", "/property/");
//         navigateGatsby(url)
//     }

//     setType = (val) => {
//         // this.setState({searchType: val}, () => {
//         //     // let type = this.state.searchType == "lettings" ? "to-rent" : "for-sale"
//         //     let url = this.props.location.pathname
//         //     if(this.state.searchType == "lettings") {
//         //         url = url.replace("to-rent", "for-sale")
//         //     } else {
//         //         url = url.replace("for-sale", "to-rent")
//         //     }
//         //     navigateGatsby(url)
//         // })
//         let url = this.props.location.pathname
//         if(val == "lettings") {
//             url = url.replace("for-sale", "to-rent")
//         } else {
//             url = url.replace("to-rent", "for-sale")
//         }
//         navigateGatsby(url)
//     }

//     render() {
//         return (
//             <>
//                 {/* <SEO title="Property to rent" description={this.state.query} /> */}
//                 <SearchTemplate
//                     {...this.state}
//                     changeStateAndUrl={this.changeStateAndUrl}
//                     changeToMapUrl={this.changeToMapUrl}
//                     changeToListUrl={this.changeToListUrl}
//                     mapView={this.props.location.pathname.includes("/property-map")}
//                     setType={this.setType}
//                 />
//             </>
//         )
//     }
// }
// export default Search;